@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

$whiteV3: #f9f9f9;
$grayV3: #8f8f8f;
$yellowV3: #3a3b39;
$blackV3: #3A3B43;

$Oswald: 'Oswald', sans-serif;
$Inter: 'Inter', sans-serif;


.landing-v3-wrapper {
  min-width: 380px;
  font-family: $Oswald;
  color: $whiteV3;
  background: $whiteV3;

  .content-restriction {
    margin: 0 auto;
    padding: 1rem 1.5rem;
  }

  .sub-header {
    color: $yellowV3;
    font-size: 1.2rem;
    font-family: $Inter;
    font-weight: 300;
    line-height: 1.3rem;
  }

  h1 {
    color: $blackV3;
    font-size: 5rem;
    text-transform: uppercase;
    line-height: 5.5rem;
  }

  h2 {
    color: $blackV3;
    font-size: 1.63rem;
    font-family: $Oswald;
    margin-bottom: 0.75rem;
    line-height: 2rem;
  }

  h3 {
    color: $blackV3;
    font-family: $Oswald;
    font-size: 2.3rem;
    margin: 2rem auto 1.5rem;
    line-height: 2.5rem;
    text-transform: uppercase;
  }

  h4 {
    color: $whiteV3;
    font-family: $Oswald;
    font-size: 1.5rem;
  }

  // Home
  .home-section {
    min-height: 100vh;

    header {
      background: $whiteV3;
      transition: all 0.3s ease;
      z-index: 999;

      .content-restriction {
        border-bottom: 0.063rem solid $whiteV3;
      }

      &.sticky-colored {
        .content-restriction {
          border-color: $yellowV3;
          max-width: 100%;
        }

        .btn-v3 {
          border: 0.063rem solid $whiteV3;
          color: $whiteV3;
          background: transparent;
          font-family: $Inter;
          font-weight: 300;
        }
      }

      .logo-v3 {
        font-size: 2rem;
        font-family: $Oswald;
        line-height: 1.7rem;
        color: $blackV3;
      }
    }

    .header-body {
      .brain-wrapper {
        width: 60%;

        img {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .content-wrapper {
        width: 67%;
        .left-side {
          padding: 1.2rem 1rem 0 0;
          width: 66%;
          border-right: 0.063rem solid $whiteV3;

          .sub-header {
            font-size: 16px;
          }
        }

        .btn-wrapper {
          padding-top: 1.2rem;
          max-width: 50rem;

          .left-side {
            padding: 1.2rem 1rem 0 0;
            width: 80%;
            border-right: 0.063rem solid $whiteV3;

            .sub-header {
              font-size: 16px;
            }
            .fixed-width {
              width: 82%;
            }
          }

          .right-side {
            width: 50%;
            padding: 0 2rem;

            .btn-v3 {
              color: $whiteV3;
              border: 0.063rem solid $whiteV3;
              font-family: $Inter;
              font-weight: 300;
              padding: 0.4rem 1rem;
              background: transparent;
              animation: pulse 1.5s infinite;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  // BTL
  .BTL-section {
    .content-restriction {
      margin-top: 3rem;
      border-top: 0.063rem solid $whiteV3;
    }

    .middle-block {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 15%;
        top: 45%;
        border-top: 0.063rem solid $grayV3;
      }
    }

    .left-block {
      position: relative;

      h4 {
        transform: rotateX(51deg) rotateZ(330deg);
        transform-style: preserve-3d;
        border-radius: 0.7rem;
        padding: 3rem;
        box-shadow:
          -0.063rem 0.063rem 0 0.063rem rgba(255, 255, 255, 0.2),
          0.063rem 0 1.5rem 0 rgba(255, 255, 255, 0.01),
          -1.8rem 2rem 1.5rem 0 rgba(255, 255, 255, 0.15);

        > img,
        > svg {
          height: 6vw;
          width: 6vw;
          min-height: 3rem;
          min-width: 3rem;
        }

        &.special {
          color: $yellowV3;
          box-shadow:
            -0.063rem 0.063rem 0 0.063rem rgba(242, 201, 85, 0.2),
            0.063rem 0 1.5rem 0 rgba(242, 201, 85, 0.01),
            -1.8rem 2rem 1.5rem 0 rgba(242, 201, 85, 0.15);
        }
      }

      &.arrow {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          height: 45%;
          border-left: 0.063rem dotted $grayV3;
          bottom: 80%;
        }

        > .double {
          > img,
          > svg {
            height: 5.1vw;
            width: 3vw;
            min-height: 1.5rem;
            min-width: 1.5rem;
          }
        }
      }
    }

    .right-block {
      font-family: $Inter;
      font-weight: 300;
    }
  }

  // Answers
  .answers-section {
    .content-restriction {
      margin-top: 3rem;
      border-top: 0.063rem solid $whiteV3;
    }

    .dl-list-wrapper {
      margin: 0 2rem;
      padding: 1.5rem 0 0;

      dt {
        color: $whiteV3;
        font-size: 1.38rem;
        font-family: $Oswald;
        line-height: 1.3rem;
        margin: 0;
        padding: 0;
        position: relative;
      }

      dd {
        margin: 0;
        padding: 1rem 0;
      }
    }

    .schema-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      width: 100%;
      margin: auto;
      font-family: $Inter;
      font-weight: 300;
      font-size: 0.8rem;

      .block {
        padding: 0.8rem 0.5rem;
        color: $whiteV3;
        font-family: $Inter;
        font-weight: 300;
        font-size: 1.8rem;
        border: 0.063rem solid $whiteV3;
        border-radius: $border-radius;
        width: 17.5%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.special {
          width: 45%;
          border: 0.063rem solid $yellowV3;
          color: $yellowV3;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0 0.5rem;
        width: 10%;
        font-size: 1.1rem;

        .arrow {
          position: relative;
          min-width: 1.563rem;
          margin: 0.25rem 0;

          &::after,
          &::before {
            content: '';
            position: absolute;
          }

          &.left,
          &.right {
            &::after {
              border-bottom: 0.063rem solid $whiteV3;
              left: 0;
              width: 100%;
            }

            &::before {
              top: 50%;
              border-top: 0.25rem solid transparent;
              border-bottom: 0.25rem solid transparent;
              transform: translateY(-0.219rem);
            }
          }

          &.left::before {
            left: 0;
            border-right: 0.25rem solid $whiteV3;
          }

          &.right::before {
            right: 0;
            border-left: 0.25rem solid $whiteV3;
          }
        }
      }
    }
  }

  // Dataverse
  .dataverse-section {
    .content-restriction {
      margin-top: 5rem;
      border-top: 0.063rem solid $whiteV3;
    }

    .content-wrapper {
      margin: 0 2rem;

      dl {
        &.dl__border-left {
          dt {
            padding: 0 1.5rem 0 0.5rem;
            position: relative;

            &.arrow::after {
              content: '';
              position: absolute;
              top: 50%;
              border-top: 0.375rem solid transparent;
              border-bottom: 0.375rem solid transparent;
              border-right: 1rem solid $whiteV3;
              transform: translateY(-60%);
              right: -0.5rem;
            }
          }

          dd {
            border-left: 0.125rem solid $whiteV3;
            border-bottom: 0.125rem solid $whiteV3;
          }
        }

        &.dl__border-right {
          dt {
            padding: 0 0.5rem 0 1.5rem;

            &.arrow::before {
              content: '';
              position: absolute;
              top: 50%;
              border-top: 0.375rem solid transparent;
              border-bottom: 0.375rem solid transparent;
              border-left: 1rem solid $whiteV3;
              transform: translateY(-60%);
              left: -0.5rem;
            }
          }

          dd {
            border-right: 0.125rem solid $whiteV3;
            border-bottom: 0.125rem solid $whiteV3;
          }
        }
      }

      dt {
        color: $whiteV3;
        font-size: 1.4rem;
        font-family: $Oswald;
        display: inline-block;
        transform: translateY(-50%);
        background: $blackV3;
        text-transform: uppercase;

        svg,
        img {
          width: 2.2rem;
          height: 2.2rem;
        }
      }

      dd {
        color: $yellowV3;
        font-size: 1.1rem;
        font-weight: 300;
        font-family: $Inter;
        padding: 0;
        margin: 0;

        ul {
          list-style: none;
          margin: 0;
          padding: 0.8rem 2rem 2rem 3rem;

          li {
            position: relative;
            margin-bottom: 1rem;
            line-height: 1.2rem;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: -1rem;
              padding: 0.25rem;
              border-radius: 50%;
              background: $whiteV3;
            }
          }
        }
      }
    }
  }

  // Footer
  .footer-section {
    font-family: $Inter;
    font-weight: 300;

    .content-restriction {
      border-top: 0.063rem solid $whiteV3;
    }

    .copyright {
      font-size: 0.8rem;
      color: $grayV3;
      text-align: center;
    }

    ul {
      list-style: none;
      font-size: 0.8rem;
      text-transform: uppercase;

      > li {
        line-height: 1.1rem;
        text-underline-offset: 0.5rem;
        opacity: 0.8;
        text-align: center;

        a { color: $whiteV3; }

        &:not(:last-child) {
          margin: 0 2.5rem 0 0;
        }

        &:hover {
          text-decoration: underline;
          opacity: 1;

          a { color: $whiteV3; }
        }
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 0.75rem rgba(255, 255, 255, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 0.75rem rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0.75rem rgba(255, 255, 255, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

// MEDIA QUERIES ==========

// 6K and >
@media all and (min-width: 6144px) {
  html, body { font-size: 50px !important; }
  .landing-v3-wrapper .content-restriction { max-width: 6000px; }
}

// Ultra HD
@media all and (min-width: 3840px) and (max-width: 6143px) {
  html, body { font-size: 37px !important; }
  .landing-v3-wrapper .content-restriction { max-width: 3800px; }
}

// Full HD
@media all and (min-width: 1980px) and (max-width: 3839px) {
  html, body { font-size: 19px !important; }
  .landing-v3-wrapper .content-restriction { max-width: 1900px; }
}

// HD
@media all and (min-width: 1280px) and (max-width: 1979px) {
  html, body { font-size: 15px !important; }
  .landing-v3-wrapper .content-restriction { max-width: 1440px; }
}

// < HD
@media all and (max-width: 1279px) {
  html, body { font-size: 14px !important; }
  .landing-v3-wrapper .content-restriction { max-width: 1000px; }
}

// Size < 768
@media all and (max-width: 767px) {
  .landing-v3-wrapper {
    .home-section {
      .header-body .content-wrapper {
        width: 100% !important;

        .left-side {
          text-align: left;
          width: 100%;
          // .sub-header {
          //   font-size: 14px;
          // }

          h2,
          .right-side { text-align: center; }
        }
      }

      h1 { margin-bottom: 1.5rem; }
    }

    h1,
    h2,
    h3,
    .sub-header {
      text-align: left;
      display: block;
    }

    .answers-section .dl-list-wrapper { margin: 0; }
    .dataverse-section .content-wrapper { margin: 0; }
  }
}

// Size < 680
@media all and (max-width: 680px) {
  html, body { font-size: 12px !important; }
}

// Size < 1080
@media all and (max-width: 1050px) {
  .landing-v3-wrapper {
    .home-section {
      .header-body .content-wrapper {

        .left-side {
          text-align: left;
          width: 100%;

          h2,
          .right-side { text-align: center; }
        }
      }

      h1 { margin-bottom: 1.5rem; }
    }
    .answers-section .dl-list-wrapper {
      dt { padding: 2rem 0; }

      .schema-wrapper {
        flex-direction: column;
        align-items: center;

        .block {
          font-size: 1.8rem;
          width: 50%;
          min-width: 180px;
        }

        ul {
          width: 120px;
          height: 130px;
          justify-content: center;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

// Size < 460
@media all and (max-width: 460px) {
  .landing-v3-wrapper
    .dataverse-section .content-wrapper dt {
      svg,
      img  { display: none; }
    }
    h1,
    h2,
    h3,
    .sub-header {
      text-align: left;
      display: block;
    }
}
